import Vue from "vue";
import VueRouter from "vue-router";

import Home from "./components/Home";
import Detail from "./components/Detail";
import Overview from "./components/Overview";

Vue.use(VueRouter);

export default new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "home",
            component: Home
        },
        {
            path: "/runs",
            name: "runs",
            component: Overview,
            meta: {
                apiUrls: [
                    "/api/runs",
                    "/api/locations"
                ]
            }
        },
        {
            path: "/runs/:id",
            name: "run",
            component: Detail,
            meta: {
                apiUrls: [
                    "/api/runs/:id/paginations",
                    "/api/runs/:id/paths",
                    "/api/runs/:id"
                ],
                target: "runs"
            }
        }
    ]
});