import { Calendar as C } from "@lgv/calendar";
import { CalendarYearLayout as CYL } from "@lgv/visualization-chart";

/**
 * CalendarYearLayout is a data-bound layout abstraction for a calendar chart by year with month/day of week grid.
 * @param {array} data - object with key/value pairs of id, label, value
 * @param {object} params - key/value pairs needed for getters
 */
class CalendarYearLayout extends CYL {
    constructor(data, params) {
        super(data, params);
    }
    configureEventDetails(d,e) {
        let item = this.lookup.get(d);
        return {
            id: this.extractId(d),
            distance: item ? item[0].distance : null,
            label: this.extractLabel(d),
            poster: item ? item[0].poster : null,
            runId: item ? item[0].id : null,
            value: this.extractValue(d),
            valueLabel: this.extractValueAsLabel(d),
            x: this.extractX(d),
            y: this.extractY(d),
            xy: [e.clientX, e.clientY]
        };
    }
}

/**
 * Calendar is a time series visualization.
 * @param {array} data - objects where each represents a path in the hierarchy
 * @param {integer} height - artboard height
 * @param {float} radiusMax - max of radii
 * @param {float} radiusMin - min of radii
 * @param {integer} width - artboard width
 */
class Calendar extends C {
    constructor(data, width=configurationLayout.width, height=configurationLayout.height) {
        super(data, width, height, new CalendarYearLayout(data, {height:height,width:width}), ["transparent", "var(--accent2)"]);   
    }
};

export { Calendar };
export default Calendar;