<template>

    <section class="detail">

        <!-- NAV/INFO -->
        <Transition name="infoslide" appear>
            <div v-if="item" class="info">
                
                <h1>zero seconds flat</h1>
                <h2>
                    <span 
                        v-if="paginations && paginations.previous"
                        @click="processPrevious">◀
                    </span>
                    {{ m(item.date).format("MMMM D, YYYY") }}
                    <span 
                        v-if="paginations && paginations.next"
                        @click="processNext">▶
                    </span>
                </h2>
                <p><strong>{{ item.area }}</strong></p>
                <p v-if="item.trail">{{ item.trail.join(", ") }} Trail{{ item.trail.length > 1 ? "s" : "" }}</p> 
                <div>
                    <p><span>{{ item.distance }}</span> miles</p>
                    <p>::</p>
                    <p><span>{{ item.duration }}</span> minutes</p>
                </div>   
            
            </div>
        </Transition>

        <!-- ROUTE MAP -->
        <Transition name="routeslide" appear>
            <div class="map">
                <Routemap 
                    v-if="geojson"
                    v-bind:paths="geojson" />
            </div>
        </Transition>

        <!-- POSTER -->
        <Transition v-if="item" name="posterslide" appear>
            <Poster v-bind:run="item" />
        </Transition>

        <!-- RETURN TO OVERVIEW -->
        <md-button class="md-icon-button md-accent" @click="processReturn">
            <md-icon>undo</md-icon>
        </md-button>

    </section>

</template>

<script>
    import axios from "axios";
    import moment from "moment";

    import Poster from "./Poster";
    import Routemap from "./chart/RouteMap";

    export default {
        name: "Detail",
        components: {
            Poster,
            Routemap,
        },
        data: () => ({
            geojson: null,
            item: null,
            m: moment,
            paginations: null,
            paths: null,
            routeOrder: ["paginations", "paths", "item"]
        }),
        mounted() {
            window.scrollTo(0,0);
            this.getData();
        },
        methods: {
            getData() {
                Promise.all(this.$route.meta.apiUrls.map(d => axios.get(d.replace(":id", this.$route.params.id))))
                .then(responses => {
                    this.routeOrder.forEach((d,i) => this[d] = responses[i].data);
                    this.geojson = {
                        type: "FeatureCollection",
                        features: this.paths.map(d => ({
                            type: "Feature",
                            geometry: d.path,
                            properties: {
                                id: d.id,
                                label: d.id
                            }
                        }))
                    };
                })
                .catch(e => e);
            },
            processNext() {
                // go to next run
                this.$router.push({ name: "run", params: { id: this.paginations.next.id }});
            },
            processPrevious() {
                // go to previous run
                this.$router.push({ name: "run", params: { id: this.paginations.previous.id }});
            },
            processReturn() {
                // go to runs
                this.$router.push({ name: "runs" });
            },
            processUpdatedTooltip(tooltip) {
                // send data upstream
                this.$emit("tooltip_updated", tooltip);
            }
        },
        watch: {
            "$route.params.id": function(newVal, oldVal) {
                if (newVal !== oldVal) this.getData();
            }
        }
    };
</script>