<template>

    <div class="poster" @click="processClick">
        <div v-bind:style="{ backgroundImage: 'url(' + IMAGE_URL + run.poster + ')' }"></div>
    </div>

</template>

<script>
    import { configuration } from "../configuration.js";

    export default {
        name: "Poster",
        props: ["run"],
        data: () => ({
            IMAGE_URL: configuration.IMAGE_URL
        }),
        methods: {
            processClick() {
                // only if the current state is not the one 
                // we want to navigate to
                if (this.$route.name !== "run") {
                    this.$router.push({ name: "run", params: { id: this.run.id } });
                }
            }
        }
    };
</script>