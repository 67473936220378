<template>

    <section class="time">

        <Transition name="timecalendar" appear>
            <Calendar
                v-bind:runs="runs"
                v-on:tooltip_updated="processUpdatedTooltip" />
        </Transition>

    </section>

</template>

<script>
    import Calendar from "../chart/Calendar";

    export default {
        name: "Time",
        props: ["runs"],
        components: {
            Calendar
        },
        methods: {
            processUpdatedTooltip(tooltip) {
                // send data upstream
                this.$emit("tooltip_updated", tooltip);
            }
        }
    };
</script>