<template>

    <div class="calendar"
        @cell-click="processHover"></div>

</template>

<script>
    import moment from "moment";
    import { Calendar } from "../../modules/calendar.js";

    export default {
        name: "Calendar",
        props: ["runs"],
        data: () => ({
            vc: null
        }),
        mounted() {
            // initial data process
            if (!this.vc) this.processData();
        },
        methods: {
            processClick(e) {

                // only if data exists
                if (e.detail.distance) {

                    // close tooltip
                    this.processUnhover(); 

                    // open individual run
                    this.$router.push({ name: "run", params: { id: e.detail.runId } });
                
                }

            },
            processData() {
                
                // format data for visualization
                let data = this.runs;

                // check if visualization has been previously rendered
                if (this.vc) {

                    // update existing
                    this.vc.update(data);

                } else {

                    let _self = this;

                    // have to wait for animation to end
                    setTimeout(function() {

                        let height = _self.$el.clientHeight;
                        let width = _self.$el.clientWidth;

                        // initialize map
                        _self.vc = new Calendar(data,width,height);

                        // initial render
                        _self.vc.render(_self.$el);

                    }, 1000);

                }

            },
            processHover(e) {

                // only if data exists
                if (e.detail.distance) {

                    // send data upstream
                    this.$emit("tooltip_updated", {
                        headline: moment(e.detail.id).format("MMMM D, YYYY"),
                        subhead: e.detail.distance ? `${e.detail.distance} miles` : "",
                        poster: e.detail.poster,
                        to: { name: "run", params: { id: e.detail.runId } },
                        x: e.detail.xy[0] + this.vc.unit,
                        y: e.detail.xy[1] - (this.vc.unit * 5)
                    });

                }
                
            },
            processUnhover() {
                // send data upstream
                this.$emit("tooltip_updated", null)
            }
        }
    };
</script>