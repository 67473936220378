import Vue from "vue";
import VueMeta from "vue-meta";

// material-vue
import { MdButton, MdCard, MdIcon, MdRadio, MdTabs, MdTooltip } from "vue-material/dist/components";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";

// app
import App from "./App.vue";
import "./style/index.scss";
import router from "./router.js";
import { configuration } from "./configuration.js";

// set as vue component
Vue.use(MdButton);
Vue.use(MdCard);
Vue.use(MdIcon);
Vue.use(MdRadio);
Vue.use(MdTabs);
Vue.use(MdTooltip);
Vue.use(VueMeta);

// render app and mount to DOM
new Vue({
    el: "#app",
    metaInfo: {
        title: "zero seconds flat",
        titleTemplate: `%s`,
        meta: [
            { property: "og;title", content: "see zero run" },
            { property: "og:image", content: `${configuration.IMAGE_URL}/posters/poster.jpg` }
        ]
    },
    router: router,
    render: h => h(App)
});