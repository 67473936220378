<template>

    <section class="latest">

        <!-- CARD -->
        <div v-for="run in latest"
            v-bind:key="run.id"
            class="latest-card">

            <hgroup @click="processClick(run.id)">

                <!-- DATE -->
                <h2><router-link :to="{ name: 'run', params: { id: run.id } }">{{ m(run.date).format("MMMM D") }}</router-link></h2>
                
                <!-- FROM NOW -->
                <p><em>{{ m(run.date).fromNow() }}</em></p>

                <!-- MILEAGE -->
                <p>{{ run.distance }} miles</p>

            </hgroup>

            <!-- POSTER -->
            <Transition name="latestposter" appear>
                <Poster v-bind:run="run" />
            </Transition>

        </div>

    </section>

</template>

<script>
    import moment from "moment";

    import Poster from "./Poster";

    export default {
        name: "Latest",
        props: ["runs"],
        components: {
            Poster
        },
        data: () => ({
            increment: 4,
            iteration: 1,
            latest: [],
            m: moment
        }),
        mounted() {

            // set initial
            this.getRuns();

            // set up infinite scroll
            this.loadMore();

        },
        methods: {
            getRuns() {
                this.latest = this.runs.filter((d,i) => i < (this.increment * this.iteration));
                ++this.iteration;
            },
            loadMore() {
                window.onscroll = () => {
                    let html = document.documentElement;
                    let bottomOfWindow = html.scrollTop + html.offsetHeight >= html.scrollHeight;
                    //let bottomOfWindow = parseInt(document.documentElement.scrollHeight - document.documentElement.scrollTop) == (document.documentElement.clientHeight + 20);
                    //let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight + 0.5;
                    if (bottomOfWindow) {
                        this.getRuns();
                    }
                }
                this.$el.onscroll = (e) => {
                    let html = e.target;
                    let bottomOfWindow = html.scrollTop + html.offsetHeight >= html.scrollHeight;
                    if (bottomOfWindow) {
                        this.getRuns();
                    }
                }
            },
            processClick(id) {
                this.$router.push({ name: "run", params: { id: id } });
            }
        }
    };
</script>