<template>

    <div class="beeswarm"
        @bubble-click="processHover">
    </div>

</template>

<script>
    import moment from "moment";

    import { Beeswarm } from "../../modules/beeswarm";

    export default {
        name: "Beeswarm",
        props: ["bin", "runs"],
        computed: {
            bins: function() {
                return {
                    duration: {
                        key: "duration",
                        values: null
                    },
                    mileage: {
                        key: "distance",
                        legend: {
                            label: "mile",
                            values: [...new Set(this.runs.map(d => d.distance % 1 >= 0.5 ? Math.ceil(d.distance) : Math.floor(d.distance)))].sort()
                        }
                    }
                }
            }
        },
        mounted() {

            let defaultBin = Object.keys(this.bins)[1];

            // send data upstream
            this.$emit("bins_updated", { default: defaultBin, values: this.bins });

            // initial data process
            if (!this.bb) this.processData(defaultBin);

        },
        methods: {
            processClick(e) {

                // close tooltip
                this.processUnhover(); 

                // open individual run
                this.$router.push({ name: "run", params: { id: e.detail.id } });

            },
            processData(bin) {
                
                // format data for visualization
                let data = this.runs.map(d => ({
                    id: d.id,
                    distance: d.distance,
                    date: d.date,
                    poster: d.poster,
                    value: 1,
                    x: d[this.bins[bin].key],
                    y: 0
                }));

                // check if visualization has been previously rendered
                if (this.bb) {

                    // update existing
                    this.bb.update(data);

                } else {

                    let _self = this;
                    setTimeout(function() {

                        let width = _self.$el.clientWidth;
                        let height = _self.$el.clientHeight;
                        let size = parseFloat(getComputedStyle(document.body).fontSize) * 1.5;

                        // initialize map
                        _self.bb = new Beeswarm(data,width,height,size);

                        // initial render
                        _self.bb.render(_self.$el);

                        // send data upstream
                        _self.$emit("data_updated", _self.bins[bin].legend.values.map((d,i) => ({ id: i, label: { key: d, value: `${_self.bins[bin].legend.label}s`}, value: _self.bb.colorScale.range()[i] })));

                    },1000);

                }

            },
            processHover(e) {
                // send data upstream
                this.$emit("tooltip_updated", {
                    headline: moment(e.detail.date).format("MMMM D, YYYY"),
                    subhead: e.detail.distance ? `${e.detail.distance} miles` : "",
                    poster: e.detail.poster,
                    to: { name: "run", params: { id: e.detail.runId } },
                    x: e.detail.xy[0] + this.bb.unit,
                    y: e.detail.xy[1] - (this.bb.unit * 5)
                });
            },
            processUnhover() {
                // send data upstream
                this.$emit("tooltip_updated", null)
            }
        },
        watch: {
            bin: function(newVal, oldVal) {
                if (newVal !== oldVal) this.processData(newVal);
            }
        }
    };
</script>