<template>

    <div class="route-map"></div>

</template>

<script>
    import { Routemap } from "@lgv/visualization-map";

    export default {
        name: "Routemap",
        props: ["paths"],
        mounted() {

            // initialize map
            this.vm = new Routemap(this.paths,null,null,null,process.env.VUE_APP_LGV_TILESERVER);

            // initial render
            this.vm.render(this.$el);

        },
        watch: {
            paths: function(newVal, oldVal) {
                if (this.vm) this.vm.update(newVal);
            }
        }
    };
</script>