<template>

    <main>

        <!-- VIDEO -->
        <video v-if="video" id="background-video" autoplay muted v-bind:style="{ zIndex: $route.name == 'home' ? 0 : -1}">
            <!-- TODO ADD POSTER ATTR -->
            <source v-bind:src="video" type="video/mp4">
        </video>

        <!-- MAIN CONTENT -->
        <Transition name="backgroundcolor" appear>
            <router-view v-slot="{ Component }" 
                v-on:tooltip_updated="processUpdatedTooltip">
                    <component :is="Component" />
            </router-view>
        </Transition>

        <!-- LOGO -->
        <Transition v-if="$route.name !== 'home'" name="runlogo" appear>
            <router-link :to="{ name: 'home' }" class="zero">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M194-80v-395h80v315h280v-193l105-105q29-29 45-65t16-77q0-40-16.5-76T659-741l-25-26-127 127H347l-43 43-57-56 67-67h160l160-160 82 82q40 40 62 90.5T800-600q0 57-22 107.5T716-402l-82 82v240H194Zm197-187L183-475q-11-11-17-26t-6-31q0-16 6-30.5t17-25.5l84-85 124 123q28 28 43.5 64.5T450-409q0 40-15 76.5T391-267Z"/></svg>
            </router-link>
        </Transition>

        <!-- TOOLTIP -->
        <TheTooltip 
            v-if="showTooltip"
            v-bind:tooltip="tooltip"
            v-on:tooltip_updated="processUpdatedTooltip" />

    </main>

</template>

<script>
    import axios from "axios";
    
    import TheTooltip from "./components/TheTooltip.vue";

    import { configuration } from "./configuration.js";

    export default {
        name: "App",
        components: {
            TheTooltip
        },
        data: () => ({
            showTooltip: false,
            tooltip: null,
            video: null
        }),
        mounted() {
            this.getData();
        },
        methods: {
            getData() {
                axios.get("/api/video")
                    .then(response => this.video = `${configuration.IMAGE_URL}${response.data}`)
                    .catch(e => e);
            },
            processUpdatedTooltip(tooltip) {
                if (tooltip) {

                    // show
                    this.showTooltip = true;
                    this.tooltip = tooltip;

                } else {

                    // hide 
                    this.showTooltip = false;
                    this.tooltip = null;

                }

            }
        },
        watch: {
            "$route.name": function(newVal, oldVal) {
                if (newVal && newVal !== oldVal && newVal == "home") {
                    
                    // get dom elment
                    let video = document.getElementById("background-video");

                    // pull new video
                    this.getData();

                    // play video
                    video.load();
                    video.play();

                }
            }
        }

    };
</script>