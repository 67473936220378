<template>

    <hgroup class="home">

        <!-- TITLE -->
        <Transition name="fadein1" appear>
            <h1>zero seconds flat</h1>
        </Transition>

        <p>

            <!-- DESCRIPTION -->
            <Transition name="fadein2" appear>
                <span>A trail running data story about a pooch who runs like a rocket with his human.</span>
            </Transition>

            <!-- LOGO -->
            <Transition name="fadein3" appear>
                <router-link :to="{ name: 'runs' }" class="zero bounce">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M194-80v-395h80v315h280v-193l105-105q29-29 45-65t16-77q0-40-16.5-76T659-741l-25-26-127 127H347l-43 43-57-56 67-67h160l160-160 82 82q40 40 62 90.5T800-600q0 57-22 107.5T716-402l-82 82v240H194Zm197-187L183-475q-11-11-17-26t-6-31q0-16 6-30.5t17-25.5l84-85 124 123q28 28 43.5 64.5T450-409q0 40-15 76.5T391-267Z"/></svg>
                </router-link>
            </Transition>

        </p>

    </hgroup>

</template>

<script>
    export default {
        name: "Home"
    };
</script>