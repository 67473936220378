import { Beeswarm as B } from "@lgv/beeswarm";
import { ForceLayout as FL } from "@lgv/visualization-chart";
import { forceCollide, forceSimulation, forceX, forceY } from "d3-force";
import { extent } from "d3-array";
import { axisTop } from "d3-axis";
import { scaleOrdinal, scaleLinear } from "d3-scale";

class ForceLayout extends FL {
    constructor(data, params) {
        super(data, params);
    }
    get layout() {
        return forceSimulation(this.data)
            .alphaTarget(0.3)
            .velocityDecay(0.1)
            .force("x", forceX().strength(0.01).x(d => this.xScale(this.extractX(d))))
            .force("y", forceY().strength(0.01).y(d => this.yScale(this.extractY(d))))
            .force("collide", forceCollide().radius(d => this.rScale(this.extractRadius(d) + 1)).iterations(3))
            .stop();
    }
    configureEventDetails(d,e) {
        return {
            id: this.extractId(d),
            date: d.date,
            distance: d.distance,
            label: this.extractLabel(d),
            poster: d.poster,
            runId: d.id,
            value: this.extractValue(d),
            valueLabel: this.extractValueAsLabel(d),
            x: this.extractX(d),
            y: this.extractY(d),
            xy: [e.clientX, e.clientY]
        };
    }
    extractLabel(d) {
        return d.distance;
    }
}

class Beeswarm extends B {
    constructor(data,width,height,maxRadius) {
        super(data,width,height,new ForceLayout(data,{width: width}),maxRadius);
    }
    get axisBottom() {
        return axisTop(this.xScale)
            .tickSize(this.height*0.85)
            .ticks(4);
    }
    get colorScale() {
        return scaleOrdinal()
            .domain([...new Set(this.data.map(d => d.x % 1 >= 0.5 ? Math.ceil(d.x) : Math.floor(d.x)))].sort())
            //.range(["#92DCE5", "#EEE5E9", "#7C7C7C", "#D64933"]);
            //.range(["#240115", "#DE3C4B", "#87F5FB", "#CEC3C1"]);
            //.range(["#E5FFDE", "#BBCBCB", "#9590A8", "#634B66"]);
            .range(["var(--viz1)", "var(--viz2)", "var(--viz3)", "var(--viz4)", "var(--viz5)", "var(--viz6)"]);
    }
    get xScale() {
        let minMax = extent(this.data, d => this.Data.extractX(d));
        return scaleLinear()
            .domain([Math.floor(minMax[0]), Math.ceil(minMax[1])])
            .range([this.maximumSize, this.width - this.maximumSize]);
    }
    configureAxisX() {
        this.axisX
            .transition().duration(1000)
            .attr("class", this.classAxisX)
            .attr("stroke", "currentColor")
            .attr("transform", `translate(0,${this.yScale.range()[0]})`)
            .call(this.axisBottom);
    }
    configureBubbles() {
        this.bubble
            .transition().duration(1000)
            .attr("class", this.classBubble)
            .attr("data-label", d => this.Data.extractLabel(d))
            .attr("data-value", d => this.Data.extractValue(d))
            .attr("fill", d => this.colorScale(d.x % 1 >= 0.5 ? Math.ceil(d.x) : Math.floor(d.x)))
            .attr("r", d => this.rScale(this.Data.extractRadius(d)));
    }
}

export { Beeswarm, ForceLayout };