<template>
    
    <section class="about">

        <hgroup>
            <h1>The Big Idea</h1>
            <h2>zero seconds flat</h2>
            <p>It's his speed. Running. Thinking. Velociraptoring. He does it all and does it <em>fast</em>. <strong>zero</strong> is the very best boy who needs to explore new places and stretch his legs. We track our adventures to know where we've been and where to explore next!</p>   
        </hgroup>

        <div class="stats">
            <h1>all time stats</h1>
            <h2>{{ runs.length }} Runs</h2>
            <h2>{{ mileage }} Miles</h2>
        </div>

        <Transition name="categoryslide" appear>
            <Category 
                v-bind:runs="runs"
                v-on:tooltip_updated="processUpdatedTooltip" />
        </Transition>

    </section>

</template>

<script>

    import { rollup, sum } from "d3-array";

    import Category from "./visualization/Category";

    export default {
        name: "About",
        props: ["runs"],
        components: {
            Category
        },
        computed: {
            mileage: function() {
                return Math.round((rollup(this.runs, v => sum(v, d => d.distance)) + Number.EPSILON) * 100) / 100;
            }
        },
        methods: {
            
            processUpdatedTooltip(tooltip) {
                // send data upstream
                this.$emit("tooltip_updated", tooltip);
            }
        }
    };
</script>