<template>

    <section class="geography">

        <Transition name="geographymap" appear>
            <ClusterMap 
                v-bind:coordinates="coordinates" />
        </Transition>

    </section>

</template>

<script>
    import ClusterMap from "../chart/ClusterMap";

    export default {
        name: "Geography",
        props: ["coordinates"],
        components: {
            ClusterMap
        }
    };
</script>