<template>

    <div class="cluster-map"></div>

</template>

<script>
    import { Clustermap } from "@lgv/visualization-map";

    export default {
        name: "ClusterMap",
        props: ["coordinates"],
        mounted() {

            // establish custom marker
            let markerIcon = L.divIcon({
                html: "<span style='font-size: 3em;text-shadow: 0 3px 10px rgba(0,0,0,0.8)'>👟</span>"
            });

            let _self = this;

            setTimeout(function() {

                // initialize map
                _self.vm = new Clustermap(_self.coordinates, null, null, null, markerIcon, process.env.VUE_APP_LGV_TILESERVER);

                // initial render
                _self.vm.render(_self.$el);

            },1000);

            // register click away detection
            window.addEventListener("click", function(e) {
                if (_self.$el.contains(e.target)) {

                    let posterNode = document.querySelector("#popup-poster");

                    if (posterNode) {
                    
                        // add click to poster
                        posterNode.addEventListener("click", function(e) {
                            _self.$router.push({ name: "run", params: { id: e.target.dataset.id }});
                        });

                    }

                }
            });

        }
    };
</script>