<template>

    <div class="category">

        <!-- LEGEND -->
        <div class="the-legend">
    
            <p v-for="item in legend"
                v-bind:key="item.id">

                <!-- SWATCH -->
                <span v-bind:style="{ background: item.value }"></span>

                <!-- LABEL -->
                <span>
                    <strong>{{  item.label.key  }}</strong>
                    {{ item.label.value }}
                </span>

            </p>

        </div>

        <Beeswarm
            v-if="runs"
            v-bind:runs="runs"
            v-on:data_updated="processUpdatedData"
            v-on:tooltip_updated="processUpdatedTooltip" />

    </div>

</template>

<script>
    import Beeswarm from "../chart/Beeswarm";

    export default {
        name: "Category",
        props: ["runs"],
        components: {
            Beeswarm
        },
        data: () => ({
            legend: null
        }),
        methods: {
            processUpdatedData(updatedData) {
                // update self
                this.legend = updatedData;
            },
            processUpdatedTooltip(tooltip) {
                // send data upstream
                this.$emit("tooltip_updated", tooltip);
            }
        }
    };
</script>