<template>

    <div class="overview">

        <Transition name="aboutslide" appear>
            <About 
                v-if="runs"
                v-bind:runs="runs"
                v-on:tooltip_updated="processUpdatedTooltip" />
        </Transition>

        <Transition name="timeslide" appear>
            <Time 
                v-if="runs"
                v-bind:runs="runs"
                v-on:tooltip_updated="processUpdatedTooltip" />
        </Transition>

        <Transition name="geographyslide" appear>
            <Geography 
                v-if="geojson"
                v-bind:coordinates="geojson" />
        </Transition>

        <Transition name="latestslide" appear>
            <Latest 
                v-if="runs"
                v-bind:runs="runs" />
        </Transition>
        
    </div>

</template>

<script>
    import axios from "axios";

    import About from "./About";
    import Geography from "./visualization/Geography";
    import Latest from "./Latest";
    import Time from "./visualization/Time";

    export default {
        name: "Overview",
        components: {
            About,
            Geography,
            Latest,
            Time
        },
        data: () => ({
            coordinates: null,
            geojson: null,
            routeOrder: ["runs", "coordinates"],
            runs: null
        }),
        mounted() {
            this.getData();
        },
        methods: {
            getData() {
                Promise.all(this.$route.meta.apiUrls.map(d => axios.get(d)))
                    .then(responses => {
                        this.routeOrder.forEach((d, i) => this[d] = responses[i].data);
                        this.geojson = {
                            type: "FeatureCollection",
                            features: this.coordinates.map(d => ({
                                type: "Feature",
                                geometry: d.center,
                                properties: d.properties
                            }))
                        };
                    })
                    .catch(e => e);
            },
            processUpdatedTooltip(tooltip) {
                // send data upstream
                this.$emit("tooltip_updated", tooltip);
            }
        }
    };
</script>
