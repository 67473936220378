<template>

    <div
        v-bind:class="['tooltip', tooltip.componentClass, { 'with-visual-attribute': showVisualValueViaAttribute },{ 'with-visual-class': showVisualValueViaClass },{ 'with-visual-style': showVisualValueViaStyle }, { 'with-poster': showPoster }]"
        v-bind:style="{ top: y, left: x, width: width }">

        <!-- POSTER -->
        <div v-if="showPoster" class="poster" @click="processClick">
            <div v-bind:style="{ backgroundImage: 'url(' + tooltip.poster + ')' }"></div>
        </div>

        <!-- CONTENT -->
        <div class="content">

            <h1>{{ tooltip.headline }}</h1>
            <h2 v-if="tooltip.subhead">{{ tooltip.subhead }}</h2>
            <p v-if="tooltip.text">{{ tooltip.text }}</p>

            <!-- VISUALS VIA ATTRIBUTE -->
            <span v-if="showVisualValueViaAttribute"
                v-bind:data-value="tooltip.visualValue"></span>

            <!-- VISUALS VIA CLASS -->
            <span v-if="showVisualValueViaClass"
                v-bind:class="tooltip.visualClass"></span>

            <!-- VISUALS VIA STYlE -->
            <span v-if="showVisualValueViaStyle"
                v-bind:style="tooltip.visualStyle"></span>

        </div>

        <!-- CLOSE -->
        <md-button class="md-icon-button md-dense" @click="close">
            <md-icon>close</md-icon>
        </md-button>

    </div>

</template>

<script>
    export default {
        name: "TheTooltip",
        props: ["tooltip"],
        computed: {
            defaultWidth: function() {
                return 360;
            },
            showPoster: function() {
                return this.tooltip && this.tooltip.poster && this.tooltip.poster !== undefined;
            },
            showVisualValueViaAttribute: function() {
                return this.tooltip && this.tooltip.visualValue !== undefined;
            },
            showVisualValueViaClass: function() {
                return this.tooltip && this.tooltip.visualClass !== undefined;
            },
            showVisualValueViaStyle: function() {
                return this.tooltip && this.tooltip.visualStyle !== undefined;
            },
            width: function() {

                let padding = 10;

                // get space
                let space = this.getEmptySpace();

                // determine width
                let shouldRenderFull = space.right >= this.defaultWidth || space.left >= this.defaultWidth;

                // width value
                let w = shouldRenderFull ? this.defaultWidth : Math.max(space.right, space.left) - (padding * 2);
                
                return `${w}px`;

            },
            x: function() {
                return `${this.getEmptySpace().x}px`;
            },
            y: function() {
                return `${this.tooltip.y + window.pageYOffset}px`;
            }
        },
        methods: {
            close() {
                // send data upstream
                this.$emit("tooltip_updated");
            },
            getEmptySpace() {
                
                let x = parseFloat(this.tooltip.x) || window.scrollX;

                // determine left/right empty space to window edge
                let availableSpaceRight = window.innerWidth - x;
                let isAvailableRightSpaceLargerThanDefault = availableSpaceRight > this.defaultWidth;
                let isAvailableLeftSpaceLargerThanDefault = x > this.defaultWidth;

                return {
                    left: x,
                    right: availableSpaceRight,
                    x: x < window.innerWidth * 0.75 ? x : (x - this.defaultWidth)
                }

            },
            processClick() {

                // check for destination route
                if (this.tooltip.to) {

                    // navigate to new state
                    this.$router.push(this.tooltip.to);

                    // send data upstream
                    this.close();

                }

            }
        }
    };
</script>